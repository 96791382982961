@use "sass:math";

$fp-animate-ease : cubic-bezier(0.23, 1, 0.32, 1);
$fp-animate-time : 300ms;

$fp-zindex : 9999;

$fp-font-family: $font-family-en;
$fp-font-size: rem(14px);
$fp-font-time-size: rem(18px);
$fp-line-height: rem(24px);

// calendar width calculation
$fp-day-size        : 32px;
$fp-padding         : 2px; // 32/16
$fp-margin          : 1px;
$fp-days-width      : ($fp-day-size *7 + $fp-margin * 14 + $fp-padding * 2 + 2);

$fp-calendar-width  : $fp-days-width;
$fp-calendar-padding: 8px;
$fp-month-nav-height: 48px;
$fp-weekdays-height : 28px;
$fp-time-height     : 40px;

$fp-day-radius      : $border-radius-sm;
$fp-day-range-radius: $border-radius-lg;

// Colors
$fp-calendar-bg          : $dropdown-bg;
$fp-calendar-border-color: $dropdown-border-color;

$fp-month-color          : #fff;
$fp-month-bg             : transparent;

$fp-weekdays-color       : rgba($body-color, .75);
$fp-weekdays-bg          : transparent;

$fp-day-color            : $body-color;
$fp-day-hover-color      : #fff;
$fp-day-hover-bg         : darken($blue-800, 5%);

$fp-today-color          : #fff;
$fp-today-border-color   : rgba($secondary , .8);

$fp-selected-color: #fff;
$fp-selected-bg          : $primary;

$fp-inverted-bg : invert($fp-calendar-bg);

// ============================================

@keyframes fpFadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.flatpickr-calendar {
    animation: none;
    background: $fp-calendar-bg;
    border: solid 1px $fp-calendar-border-color;
    border-radius: $border-radius;
    box-sizing: border-box;
    display: none;
    visibility: hidden;
    direction: ltr;
    font-family: $fp-font-family;
    font-size: $fp-font-size;
    line-height: $fp-line-height;
    opacity: 0;
    padding: 0 $fp-calendar-padding $fp-calendar-padding;
    position: absolute;
    text-align: center;
    touch-action: manipulation;
    width: $fp-calendar-width + ($fp-calendar-padding * 2);

    input::selection{
        background: transparent !important;
    }

    &.open,
    &.inline {
        opacity: 1;
        max-height: 640px;
        visibility: visible;
    }

    &.open {
        display: inline-block;
        z-index: $fp-zindex;
    }

    &.animate.open {
        animation: fpFadeInDown $fp-animate-time $fp-animate-ease;
    }

    &.inline {
        display: block;
        position: relative;
        top: 2px;
    }

    &.static {
        position: absolute;
        top: calc(100% + 2px);

        &.open {
            z-index: $fp-zindex;
            display: block;
        }
    }

    &.multiMonth {
        .flatpickr-days .dayContainer:nth-child(n+1) {
            .flatpickr-day.inRange:nth-child(7n+7) {
                box-shadow: none !important;
            }
        }

        .flatpickr-days .dayContainer:nth-child(n+2) {
            .flatpickr-day.inRange:nth-child(7n+1) {
                box-shadow: -2px 0 0 $fp-day-hover-bg, 5px 0 0 $fp-day-hover-bg;
            }
        }
    }

    .hasWeeks .hasTime {
        .dayContainer {
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &.hasTime {
        .flatpickr-time {
            height: $fp-time-height;
            border-top: 1px solid $fp-calendar-border-color;
        }
    }

    &.noCalendar.hasTime {
        .flatpickr-time {
            height: auto;
        }
    }

    &:before,
    &:after {
        position: absolute;
        display: block;
        pointer-events: none;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        left: 22px;
    }

    &.rightMost,
    &.arrowRight {

        &:before,
        &:after {
            left: auto;
            right: 22px;
        }
    }

    &.arrowCenter {

        &:before,
        &:after {
            left: 50%;
            right: 50%;
        }
    }

    &:before {
        border-width: 8px;
        margin: 0 -8px;
    }

    &:after {
        border-width: 7px;
        margin: 0 -7px;
    }

    &.arrowTop {

        &:before,
        &:after {
            bottom: 100%;
        }

        &:before {
            border-bottom-color: $fp-calendar-border-color;
        }

        &:after {
            border-bottom-color: $fp-calendar-bg;
        }
    }

    &.arrowBottom {

        &:before,
        &:after {
            top: 100%;
        }

        &:before {
            border-top-color: $fp-calendar-border-color;
        }

        &:after {
            border-top-color: $fp-calendar-bg;
        }
    }

    &:focus {
        outline: 0;
    }
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block;
}

.flatpickr-months {
    display: flex;

    .flatpickr-month {
        background: $fp-month-bg;
        color: $fp-month-color;
        fill: $fp-month-color;
        height: $fp-month-nav-height;
        line-height: 1;
        text-align: center;
        position: relative;
        user-select: none;
        overflow: hidden;
        flex: 1;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
        user-select: none;
        text-decoration: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        height: $fp-month-nav-height;
        padding: 10px; 
        z-index: 3;
        color: $fp-month-color;
        fill: $fp-month-color;

        &.flatpickr-disabled {
            display: none;
        }

        i {
            position: relative;
        }

        &.flatpickr-prev-month {
            left: 0;
            padding-left: 14px;
            padding-right: 6px;
        }

        &.flatpickr-next-month {
            right: 0;
            padding-left: 6px;
            padding-right: 14px;
        }

        &:hover {
            color: $fp-today-color;

            svg {
                fill: $fp-today-color;
                path {
                    stroke: $fp-today-color;
                }
            }
        }

        svg {
            width: 16px;
            height: 16px;
            

            path {
                transition: fill 0.1s;
                fill: inherit;
                stroke: $body-color;
                stroke-width: 1;
            }
        }
    }
}

.numInputWrapper {
    position: relative;
    height: auto;

    input span {
        display: inline-block;
    }

    input {
        width: 100%;

        &::-ms-clear {
            display: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }
    }

    span {
        position: absolute;
        right: 0;
        width: 18px;
        padding: 0 6px 0 4px;
        height: 50%;
        line-height: 50%;
        opacity: 0;
        cursor: pointer;
        border: 1px solid rgba($fp-day-color, 0.15);
        box-sizing: border-box;

        &.arrowUp {
            top: 0;
            border-bottom: 0;

            &:after {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 4px solid rgba($fp-day-color, 0.6);
                top: 26%;
            }
        }

        &.arrowDown {
            top: 50%;

            &:after {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid rgba($fp-day-color, 0.6);
                top: 40%;
            }
        }

        &:hover {
            background: lighten($fp-calendar-bg, 5%);
            color: #fff;
            &.arrowUp:after {
                border-bottom-color: #fff;
            }
            &.arrowDown:after {
                border-top-color: #fff;
            }
        }

        &:active {
            background: rgba($fp-inverted-bg, 0.2);
        }

        &:after {
            display: block;
            content: "";
            position: absolute;
        }

        

        svg {
            width: inherit;
            height: auto;

            path {
                fill: rgba($fp-month-color, 0.5);
            }
        }
    }

    &:hover {
        background: lighten($fp-calendar-bg, 5%);
        span {
            opacity: 1;
        }
    }
}

.flatpickr-current-month {
    font-size: rem(16px);
    line-height: inherit;
    font-weight: 400;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 0.22 * $fp-month-nav-height 0 0 0;
    line-height: 1;
    height: $fp-month-nav-height;
    display: inline-block;
    text-align: center;
    transform: translate3d(0px, 0px, 0px);

    span.cur-month {
        font-family: inherit;
        font-weight: 700;
        color: inherit;
        display: inline-block;
        margin-left: 0.5ch;
        padding: 0;

        &:hover {
            background: rgba($fp-inverted-bg, 0.05);
        }
    }

    .numInputWrapper {
        width: 6.5ch;
        display: inline-block;

        span.arrowUp:after {
            border-bottom-color: $fp-month-color;
        }

        span.arrowDown:after {
            border-top-color: $fp-month-color;
        }
    }

    input.cur-year {
        background: transparent;
        box-sizing: border-box;
        color: $body-color;
        cursor: text;
        padding: .5ch;
        margin: 0;
        display: inline-block;
        font-size: inherit;
        font-family: inherit;
        font-weight: 500;
        line-height: inherit;
        height: auto;
        border: 0;
        border-radius: 0;
        vertical-align: initial;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;

        &:focus {
            outline: 0;
        }

        &:focus,
        &:hover{
            color: $fp-day-hover-color;
        }

        &[disabled],
        &[disabled]:hover {
            font-size: 100%;
            color: rgba($fp-month-color, 0.5);
            background: transparent;
            pointer-events: none;
        }
    }

    .flatpickr-monthDropdown-months {
        appearance: menulist;
        background: $fp-month-bg;
        border: none;
        border-radius: 0;
        box-sizing: border-box;
        color: $body-color;
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        font-weight: 500;
        height: auto;
        line-height: inherit;
        margin: -1px 6px 0 0;
        outline: none;
        padding: 0 0 0 0.5ch;
        position: relative;
        vertical-align: initial;
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
        width: auto;

        &:focus,
        &:active {
            outline: none;
        }

        &:focus,
        &:hover{
            color: $fp-day-hover-color;
        }

        // &:hover {
        //     background: rgba($fp-inverted-bg, 0.05);
        // }

        .flatpickr-monthDropdown-month {
            background-color: $fp-month-bg;
            outline: none;
            padding: 0;
        }
    }
}

.flatpickr-weekdays {
    background: $fp-weekdays-bg;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: $fp-weekdays-height;

    .flatpickr-weekdaycontainer {
        display: flex;
        flex: 1;
    }
}

span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: $fp-month-bg;
    color: $fp-weekdays-color;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    flex: 1;
    font-weight: bolder;
}

.dayContainer .flatpickr-weeks {
    padding: 1px 0 0 0;
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    width: $fp-days-width;

    &:focus {
        outline: 0;
    }
}

.dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: $fp-days-width;
    min-width: $fp-days-width;
    max-width: $fp-days-width;
    box-sizing: border-box;
    display: inline-block;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;

    +.dayContainer {
        box-shadow: -1px 0 0 $fp-calendar-border-color;
    }
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: $fp-day-radius;
    box-sizing: border-box;
    color: $fp-day-color;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    flex-basis: 14.2857143%;
    height: $fp-day-size;
    max-width: $fp-day-size;
    width: 14.2857143%;
    margin: 0;
    position: relative;
    text-align: center;

    &:hover,
    &:focus,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus,
    &.inRange,
    &.inRange.prevMonthDay,
    &.inRange.nextMonthDay,
    &.today.inRange,
    &.today.prevMonthDay.inRange,
    &.today.nextMonthDay.inRange {
        cursor: pointer;
        outline: 0;
        color: $fp-day-hover-color;
        background: $fp-day-hover-bg;
        border-color: $fp-day-hover-bg;
    }


    &.today {
        border-color: $fp-today-border-color;

        &:hover,
        &:focus {
            border-color: $fp-today-color;
            background: $fp-today-color;
        }
    }

    &.selected,
    &.startRange,
    &.endRange {

        &.inRange,
        &:focus,
        &:hover,
        &.prevMonthDay,
        &.nextMonthDay {
            background: $fp-selected-bg;
            box-shadow: none;
            border-color: $fp-selected-bg;
        }

        &.startRange {
            border-radius: $fp-day-range-radius 0 0 $fp-day-range-radius;
        }

        &.endRange {
            border-radius: 0 $fp-day-range-radius $fp-day-range-radius 0;
        }

        &.startRange+.endRange:not(:nth-child(7n+1)) {
            box-shadow: -5 * $fp-margin 0 0 $fp-selected-bg;
        }

        &.startRange.endRange {
            border-radius: $fp-day-range-radius;
        }
    }

    &.inRange {
        border-radius: 0;
        box-shadow: -2.5 * $fp-margin 0 0 $fp-day-hover-bg, 2.5 * $fp-margin 0 0 $fp-day-hover-bg;
    }

    &.flatpickr-disabled,
    &.flatpickr-disabled:hover,
    &.prevMonthDay,
    &.nextMonthDay,
    &.notAllowed,
    &.notAllowed.prevMonthDay,
    &.notAllowed.nextMonthDay {
        color: rgba($fp-day-color, 0.5);
        background: transparent;
        cursor: default;
    }

    &.flatpickr-disabled,
    &.flatpickr-disabled:hover {
        cursor: not-allowed;
        color: rgba($fp-day-color, .2);
    }

    &.week.selected {
        border-radius: 0;
        box-shadow: -2.5 * $fp-margin 0 0 $fp-selected-bg, 2.5 * $fp-margin 0 0 $fp-selected-bg;
    }

    &.hidden {
        visibility: hidden;
    }
}

.rangeMode .flatpickr-day {
    margin-top: 1px;
}

.flatpickr-weekwrapper {
    float: left;

    .flatpickr-weeks {
        padding: 0 12px;
        box-shadow: 1px 0 0 $fp-calendar-border-color;
    }

    .flatpickr-weekday {
        float: none;
        width: 100%;
        line-height: $fp-weekdays-height;
    }

    span.flatpickr-day {

        &,
        &:hover {
            display: block;
            width: 100%;
            max-width: none;
            color: rgba($fp-day-color, 0.3);
            background: transparent;
            cursor: default;
            border: none;
        }
    }
}

.flatpickr-innerContainer {
    display: block;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
}

.flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: $fp-time-height;
    max-height: $fp-time-height;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .numInputWrapper {
        flex: 1;
        width: 40%;
        height: $fp-time-height;
        float: left;
        //margin-left: 6px;

        span.arrowUp:after {
            border-bottom-color: $fp-day-color;
        }

        span.arrowDown:after {
            border-top-color: $fp-day-color;
        }
    }

    &.hasSeconds .numInputWrapper {
        width: 26%;
    }

    &.time24hr .numInputWrapper {
        width: 49%;
    }

    input {
        background: transparent;
        box-sizing: border-box;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        color: $fp-day-color;
        font-size: $fp-font-time-size;
        font-weight: 400;
        line-height: inherit;
        height: inherit;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;       
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        &:focus {
            outline: 0;
            border: 0;
        }
        &:focus,
        &:hover{
            color: $fp-day-hover-color;
        }
    }

    .flatpickr-time-separator {
        height: inherit;
        float: left;
        line-height: inherit;
        color: $fp-day-color;
        font-weight: bold;
        width: 2%;
        user-select: none;
        align-self: center;
    }

    .flatpickr-am-pm {
        font-size: rem(16px);
        outline: 0;
        width: 18%;
        cursor: pointer;
        text-align: center;
        font-weight: 500;
        &:hover {
            color: $fp-day-hover-color;
            background: lighten($fp-day-hover-bg, 3);
        }
    }

    input .flatpickr-am-pm {

        &:hover,
        &:focus {
            color: $fp-day-hover-color;
            background: lighten($fp-day-hover-bg, 3);
        }
    }
}

.flatpickr-input {
    font-family: $fp-font-family;
    
    &[readonly]{
        cursor: pointer;
    }

    &:disabled,
    &[readonly] {
        background-color: $input-bg;
    }
}

//===============================================
// * month select
//===============================================
.flatpickr-monthSelect-months {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.flatpickr-monthSelect-month {
    background: none;
    border: 1px solid transparent;
    border-radius: $fp-day-radius;
    box-sizing: border-box;
    color: $body-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 0;
    padding: 6px 8px;
    position: relative;
    text-align: center;
    width: math.percentage(math.div(1, 3));

    &.flatpickr-disabled {
        &,
        &:hover,
        &:focus{
            color: rgba($fp-day-color, .2);
            cursor: not-allowed;
            background: transparent !important;
        }
        
    }

    &.today{
        border-color: $fp-today-border-color;
    }

    &.inRange,
    &.inRange.today,
    &:hover,
    &:focus {
        background-color: $fp-day-hover-bg;
        border-color: transparent;
        color: $fp-day-hover-color;
        cursor: pointer;
        outline: 0;
    }

    &.today:hover,
    &.today:focus {
        background-color:  $fp-day-hover-bg;
        color: $fp-day-hover-color;
        border-color: transparent;
    }

    &.selected,
    &.startRange,
    &.endRange {
        background-color: $fp-selected-bg;
        box-shadow: none;
        color: $fp-day-hover-color;
        border-color: transparent;
    }

    &.startRange {
        border-radius: $fp-day-range-radius 0 0 $fp-day-range-radius;
    }

    &.endRange {
        border-radius: 0 $fp-day-range-radius $fp-day-range-radius 0;
    }

    &.startRange.endRange {
        border-radius: $fp-day-range-radius;
    }

    &.inRange {
        border-radius: 0;
        box-shadow: -5px 0 0 $fp-day-hover-bg, 5px 0 0 $fp-day-hover-bg;
    }
}

//===============================================
// *  My customed
//===============================================

// .flatpickr-time {
//     .flatpickr-am-pm,
//     input {
//         color: $form-text-color;
//     }
// }

.flatpickr-day {
    //color: $body-color;

    &.endRange,
    &.startRange {
        color: $fp-selected-color;
        background-color: $fp-selected-bg !important;
        border: 0;

        &:focus,
        &:hover{
            background-color: $fp-selected-bg !important;
        }
    }

    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
        background-color: $fp-day-hover-bg;
        border-color: transparent;
    }

    &.today {
        &:focus,
        &:hover{
            background-color: $fp-calendar-bg;
            color: $fp-day-hover-color;
        }
    }

    &.selected {
        &,
        &:focus,
        &:hover {
            background-color: $fp-selected-bg;
            border-color: $fp-selected-bg;
            color: $fp-selected-color;
        }
    }
}

// .flatpickr-time {
//     input.flatpickr-hour {
//         border-top-right-radius: 4px;
//         border-bottom-right-radius: 5px;
//     }
// }

// .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
//     border-top: 0 !important;
// }

// .flatpickr-calendar.hasTime .flatpickr-time {
//     border-top-color: $border-color !important;
// }

.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: $fp-calendar-bg !important;
}

.flatpickr-wrapper {
    display: block;
}

// .flatpickr-months {
//     .flatpickr-prev-month,
//     .flatpickr-next-month {
//         fill: $body-color;

//         &:hover svg {
//             fill: $fp-day-hover-color;
//         }
//     }
// }