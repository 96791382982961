.table.dataTable{
    tfoot tr{
        > .dtfc-fixed-left,
        > .dtfc-fixed-right{
            top: 0;
            bottom: 0;
            z-index: 3;
            background-color: $body-bg;
        }
    }

    thead tr{
        > .dtfc-fixed-left,
        > .dtfc-fixed-right{
            background-color: $table-thead-bg;
            z-index: 1;
        }
    }

    tbody tr{
        > .dtfc-fixed-left,
        > .dtfc-fixed-right{
            z-index: 1;
            background-color: $body-bg;
        }
    }

    &.table-bordered.dtfc-has-left{
        border-left: none;
    }
}

.table.table-bordered.dataTable{
    // !!! 修正table-bordered scroll body 縫隙問題 !!!
    border-collapse: separate;
    border-spacing: 0;
}

.modal .table.dataTable{
    tbody tr,
    tfoot tr{
        > .dtfc-fixed-left,
        > .dtfc-fixed-right{
            background-color: $modal-content-bg;
        }
    }
}

.card-body .table.dataTable{
    tbody tr,
    tfoot tr{
        > .dtfc-fixed-left,
        > .dtfc-fixed-right{
            background-color: $card-bg;
        }
    }
}

div.dtfc-left-top-blocker,
div.dtfc-right-top-blocker {
    // background-color: white; //不曉得作用先移除
    margin-top: 6px;
    border-bottom: 0px solid $table-border-color !important;
}

div.dataTables_scroll.dtfc-has-left table.table-bordered {
  border-left: none;
}

// div.dataTables_scrollBody {
//   border-left: 1px solid $table-border-color !important; //不曉得作用先移除
// }

div.dataTables_scrollFootInner,
div.dataTables_scrollHeadInner{
    table.table-bordered tr th:first-child{
        border-left: 1px solid $table-border-color !important;
    }
}