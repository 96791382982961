.datepicker {
    border: 1px solid $dropdown-border-color;
    font-family: $font-family-en;
    padding: 8px;
    z-index: $zindex-dropdown !important;

    table {
        tr {
            th {
                font-weight: 500;
            }
            td {
                &.today,
                &.today:hover,
                &.today.disabled,
                &.today.disabled:hover{
                    
                    background-color: lighten($gray-700, 5%);
                    color: #fff !important;
                    font-weight: 500;

                }

                &.active,
                &.active:hover,
                .active.disabled,
                &.active.disabled:hover,
                &.selected,
                &.selected:hover,
                &.selected.disabled,
                &.selected.disabled:hover {
                    background-color: $blue !important;
                    background-image: none;
                    box-shadow: none;
                    color: #fff !important;
                }

                &.day.focused,
                &.day:hover,
                span.focused,
                span:hover {
                    color: #fff;
                    background: rgba($blue, .2);
                }

                &.new,
                &.old,
                span.new,
                span.old {
                    color: $gray-500;
                    opacity: 0.6;
                }

                &.range,
                &.range.disabled,
                &.range.disabled:hover,
                &.range:hover {
                    border-radius: 0;
                    color: #fff;
                    background-color: rgba($blue, .2);
                }

                &.range-start:not(.range-end){
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &.range-end:not(.range-start){
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

}