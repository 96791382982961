table.dataTable {

    td:focus {
        outline: none;
    }

    td.dt-control {
        text-align: center;
        cursor: pointer;

        &:before {
            height: 1em;
            width: 1em;
            margin-top: -9px;
            display: inline-block;
            color: white;
            //border: 0.15em solid white;
            border-radius: 1em;
            //box-shadow: 0 0 0.2em #444;
            box-sizing: content-box;
            text-align: center;
            text-indent: 0 !important;
            font-family: "Courier New", Courier, monospace;
            line-height: 1em;
            content: "+";
            background-color: $primary;
        }
    }

    tr.dt-hasChild td.dt-control:before {
        content: "-";
        background-color: $primary;
    }

    th,
    td {
        &.dt-left {
            text-align: left;
        }

        &.dt-right {
            text-align: right;
        }

        &.dt-center,
        &.dataTables_empty {
            text-align: center;
        }

        &.dt-justify {
            text-align: justify;
        }

        &.dt-nowrap {
            white-space: nowrap;
        }
    }

    thead,
    tfoot {
        th,
        td {
            text-align: left;

            &.dt-head-left {
                text-align: left;
            }

            &.dt-head-center {
                text-align: center;
            }

            &.dt-head-right {
                text-align: right;
            }

            &.dt-head-justify {
                text-align: justify;
            }

            &.dt-head-nowrap {
                white-space: nowrap;
            }
        }
    }

    tbody th,
    tbody td {
        &.dt-body-left {
            text-align: left;
        }

        &.dt-body-center {
            text-align: center;
        }

        &.dt-body-right {
            text-align: right;
        }

        &.dt-body-justify {
            text-align: justify;
        }

        &.dt-body-nowrap {
            white-space: nowrap;
        }
    }

    &.nowrap {
        th,
        td {
            white-space: nowrap;
        }
    }

    // Sorting
    // ========================
    thead > tr > th,
    thead > tr > td{
        &.sorting,
        &.sorting_asc,
        &.sorting_desc,
        &.sorting_asc_disabled,
        &.sorting_desc_disabled{
            cursor: pointer;
            position: relative;
            padding-right: 26px;

            &:before,
            &:after{
                display: block;
                font: normal normal normal 24px/1 "Material Design Icons" !important;
                opacity: .25;
                text-rendering: auto;
                position: absolute;
                left: auto;
                right: 2px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            &:before{
                top: calc(50% - 14px);
                content: '\F0360';
            }
            &:after{
                bottom: calc(50% - 18px);
                content: '\F035D';
            }
        }

        &.sorting_asc:before,
        &.sorting_desc:after {
            opacity: 1;
        }

        &.sorting_asc_disabled:before,
        &.sorting_desc_disabled:after{
            display: none;
        }

        &:active{
            outline: none;
        }
    }
}

div.dataTables_scrollBody table.dataTable thead > tr {
    > th,
    > td {
        &:before,
        &:after {
            display: none;
        }
    }
}

div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 2px;

    > div:last-child {
        position: relative;
        width: 80px;
        height: 15px;
        margin: 1em auto;

        > div {
            position: absolute;
            top: 0;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: rgba(13, 110, 253, 0.9);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);

            &:nth-child(1) {
                left: 8px;
                animation: datatables-loader-1 0.6s infinite;
            }

            &:nth-child(2) {
                left: 8px;
                animation: datatables-loader-2 0.6s infinite;
            }

            &:nth-child(3) {
                left: 32px;
                animation: datatables-loader-2 0.6s infinite;
            }

            &:nth-child(4) {
                left: 56px;
                animation: datatables-loader-3 0.6s infinite;
            }
        }
    }
}

@keyframes datatables-loader-1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes datatables-loader-3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes datatables-loader-2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

/* =====================================
! Custom
======================================= */
table.dataTable {
    clear: both;
    max-width: none;
    border-collapse: collapse;
    border-spacing: 0;

    th,
    td {
        box-sizing: content-box;
    }

    tbody {
        // Multi select table
        > tr.selected,
        > tr > .selected {
            background-color: rgba($primary, 0.2);

            td {
                border-color: rgba($primary, 0.2);
                color: $primary;
            }
        }

        td:focus {
            outline: none !important;
        }

        // Key Tables
        th.focus,
        td.focus {
            outline: 2px solid $primary !important;
            outline-offset: -1px;
            background-color: rgba($primary, 0.15);
        }
    }

    &.dtr-inline.collapsed > tbody > tr > td {
        position: relative;

        &.dtr-control {
            padding-left: 30px;

            &:before {
                top: 64%;
                left: 5px;
                height: 14px;
                width: 14px;
                margin-top: -14px;
                display: block;
                position: absolute;
                color: $white;
                border: 2px solid $white;
                border-radius: 14px;
                box-sizing: content-box;
                text-align: center;
                text-indent: 0 !important;
                line-height: 12px;
                content: '+';
                background-color: $primary;
            }
        }
    }
}

div.dataTables_wrapper {
    div.dataTables_length {
        margin-bottom: 8px;

        label {
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
        }

        select {
            width: auto;
            display: inline-block;
        }
    }

    div.dataTables_filter {
        margin-bottom: 8px;
        text-align: right;
        

        label {
            font-weight: normal;
            white-space: nowrap;
            text-align: left;
        }

        input {
            display: inline-block;
            margin-left: 0.5em;
            margin-right: 0;
            width: auto;
        }
    }

    div.dataTables_info {
        margin-top: 8px;
        padding-top: 0.5em;
    }

    div.dataTables_paginate {
        margin: 8px 0 0 0;
        white-space: nowrap;
        text-align: right;
        ul.pagination {
            margin: 2px 0;
            white-space: nowrap;
            justify-content: flex-end;
        }
    }
    div.dt-row {
        position: relative;
    }
}

div.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody {
    > table {
        border-top: none;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        > thead {
            .sorting,
            .sorting_asc,
            .sorting_desc {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
        > tbody tr:first-child {
            th,
            td {
                border-top: none;
            }
        }
    }
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
    box-sizing: content-box;
    > table {
        margin-top: 0 !important;
        border-top: none;
    }
}

// @media screen and (max-width: 767px) {
//     div.dataTables_wrapper {
//         div.dataTables_length,
//         div.dataTables_filter,
//         div.dataTables_info,
//         div.dataTables_paginate {
//             text-align: center;
//         }
//         div.dataTables_paginate ul.pagination {
//             justify-content: center !important;
//         }
//     }
// }

table.dataTable.table-sm > thead > tr > th:not(.sorting_disabled) {
    padding-right: 20px;
}

table.table-bordered.dataTable {
    border-right-width: 0;

    thead tr:first-child {
        th,
        td {
            border-top-width: 1px;
        }
    }

    th,
    td {
        border-left-width: 0;
        border-bottom-width: 1px;

        &:first-child {
            border-left-width: 1px;
        }
        &:last-child {
            border-right-width: 1px;
        }
    }
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0;

    > div[class^="col-"] {
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
}

.dataTables_wrapper.container-fluid {
    padding: 0;
}

// Responsive data table
table.dataTable.dtr-inline.collapsed {
    > tbody {
        > tr[role='row'] {
            > td,
            > th {
                &:first-child {
                    &:before {
                        box-shadow: $box-shadow-lg;
                        background-color: $success;
                        bottom: auto;
                    }
                }
            }
        }

        > tr.parent {
            > td,
            > th {
                &:first-child {
                    &:before {
                        background-color: $danger;
                    }
                }
            }
        }
    }
}

// Data Table copy button
div.dt-button-info {
    background-color: $primary;
    border: none;
    color: $white;
    box-shadow: none;
    border-radius: 3px;
    text-align: center;
    z-index: 21;

    h2 {
        border-bottom: none;
        background-color: rgba($white, 0.2);
        color: $white;
    }
}

@include media-breakpoint-down(md) {
    li.paginate_button.previous,
    li.paginate_button.next {
        display: inline-block;
        font-size: 1.5rem;
    }

    li.paginate_button {
        display: none;
    }

    .dataTables_paginate {
        ul {
            text-align: center;
            display: block;
            margin: $spacer 0 0 !important;
        }
    }

    div.dt-buttons {
        display: inline-table;
        margin-bottom: $spacer;
    }
}

// Active status
.activate-select {
    .sorting_1 {
        background-color: $gray-900;
    }
}